/* Layout */
#wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  perspective: 1000px;
  position: relative;
  min-height: 100%;
  padding-top: 2em;
  z-index: 2;
}

#main {
  transform-origin: 50% 50%;
  transition: opacity 1s ease, transform 1s ease;
  padding: 2.5em 3em 1em;
  background: #ffffff;
  border-radius: 15px;
  max-width: 100%;
  opacity: 0.95;
  position: relative;
  text-align: center;
  width: 42em;
}

#blogMain {
  composes: main;
  padding: 4.5em 3em 3em;
  width: 90%;
}

#blogBody {
  composes: main;
  background: #f2f2f2;
}

@media screen and (max-width: 480px) {
  #wrapper { padding: 0.75em; }
  #main {
    padding: 4em 2em 2.5em;
    width: 100%;
  }
}
