/* Navigation Styles */
.navBar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-2);
  padding: var(--space-1) 0;
  position: relative;
  font-size: var(--font-size-md);
}

.navBar a {
  color: var(--color-neutral-100);
  text-decoration: none;
  padding: var(--space-1) var(--space-3);
  border-radius: var(--radius-md);
  position: relative;
  font-weight: var(--font-weight-medium);
  transition: all var(--transition-normal);
}

/* Mobile Navigation */
@media screen and (max-width: 768px) {
  .navBar {
    gap: var(--space-4);
    font-size: var(--font-size-base);
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 480px) {
  .navBar {
    gap: var(--space-2);
    font-size: var(--font-size-sm);
    padding: var(--space-2) 0;
  }

  .navBar a {
    padding: var(--space-1) var(--space-2);
  }
}

/* Animation for Navigation Items */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navBar a {
  animation: slideIn 0.3s ease-out forwards;
}

.navBar a:nth-child(1) {
  animation-delay: 0.1s;
}
.navBar a:nth-child(2) {
  animation-delay: 0.2s;
}
.navBar a:nth-child(3) {
  animation-delay: 0.3s;
}
.navBar a:nth-child(4) {
  animation-delay: 0.4s;
}
