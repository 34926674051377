@import "./modules/reset.css";
@import "./modules/typography.css";
@import "./modules/layout.css";
@import "./modules/navigation.css";
@import "./modules/design-system.css";
@import "./modules/blog.css";

/* Base Styles */
html {
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  height: 100%;
  font-family: "Source Sans Pro", system-ui, -apple-system, sans-serif;
  font-size: var(--font-size-base);
  line-height: var(--line-height-normal);
  color: var(--color-neutral-600);
  background-color: var(--color-neutral-100);
  background-image: url("images/overlay.png"),
    linear-gradient(60deg, rgba(255, 165, 150, 0.5) 5%, rgba(0, 228, 255, 0.35)),
    url("../../images/bg.jpg");
  background-repeat: repeat, no-repeat, no-repeat;
  background-size: 100px 100px, cover, cover;
  background-position: top left, center center, bottom center;
  background-attachment: fixed, fixed, fixed;
  -webkit-text-size-adjust: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #313f47;
  line-height: 1.5;
  margin: 0 0 0.75em 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}
/* Site Header */
.site-header {
  text-align: center;
}

.header-content {
  max-width: var(--container-md);
  margin: 0 auto;
}

h3 {
  font-size: var(--font-size-xl);
}
h4 {
  font-size: var(--font-size-lg);
}
h5 {
  font-size: var(--font-size-base);
}
h6 {
  font-size: var(--font-size-sm);
}

p {
  margin-bottom: var(--space-4);
  line-height: var(--line-height-relaxed);
  color: var(--color-neutral-500);
}

a {
  color: var(--color-primary-500);
  text-decoration: none;
  transition: all var(--transition-normal);
  font-weight: var(--font-weight-medium);
}

a:hover {
  color: var(--color-primary-600);
}

/* Avatar Component */
.avatar-container {
  margin: var(--space-8) 0;
}

.avatar {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 180px;
  height: 180px;
  padding: var(--space-2);
  border-radius: var(--radius-full);
  transition: transform var(--transition-normal);
}

.avatar img:hover {
  transform: scale(1.05);
}

.avatar img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--radius-full);
  box-shadow: var(--shadow-lg);
  transition: all var(--transition-normal);
}

.avatar:hover {
  transform: scale(1.01);
}

.avatar:hover img {
  box-shadow: 0 0 0 4px var(--color-neutral-100),
    0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0.1);
}

.avatar::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -100%;
  width: 300%;
  height: 1px;
  z-index: -1;
  background: linear-gradient(
    to right,
    transparent,
    var(--color-primary-200),
    transparent
  );
}

/* Main Content */
#main,
#blogMain {
  padding: var(--space-6) var(--space-6) var(--space-2);
  background: rgba(255, 255, 255, 0.98);
  border-radius: var(--radius-2xl);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), var(--shadow-lg);
  max-width: var(--container-lg);
  margin: 0 auto;
  backdrop-filter: blur(10px);
  transform: translateY(0);
  transition: all var(--transition-normal);
}

#main:hover,
#blogMain:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 20px 25px -5px rgba(0, 0, 0, 0.2),
    0 10px 10px -5px rgba(0, 0, 0, 0.1);
}

#blogBody {
  background: var(--color-neutral-100);
  border-radius: var(--radius-xl);
  padding: var(--space-6);
  margin-top: var(--space-4);
  border: 1px solid var(--color-neutral-200);
}

/* Content Sections */
.content-section {
  margin: var(--space-8) 0;
  padding: var(--space-6);
  background: rgba(255, 255, 255, 0.5);
  border-radius: var(--radius-xl);
  border: 1px solid var(--color-neutral-200);
}

.content-section:first-child {
  margin-top: 0;
}

.content-section:last-child {
  margin-bottom: 0;
}

/* Form Elements */
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select,
textarea {
  appearance: none;
  background: var(--color-neutral-100);
  border: 1px solid var(--color-neutral-300);
  border-radius: var(--radius-lg);
  color: var(--color-neutral-600);
  display: block;
  outline: 0;
  padding: var(--space-3) var(--space-4);
  text-decoration: none;
  width: 100%;
  transition: all var(--transition-normal);
  font-size: var(--font-size-base);
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
select:focus,
textarea:focus {
  border-color: var(--color-primary-400);
  box-shadow: 0 0 0 3px var(--color-primary-200);
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  display: inline-block;
  height: 2.75em;
  line-height: 2.75em;
  padding: 0 1.5em;
  background-color: transparent;
  border-radius: 4px;
  border: solid 1px #c8cccf;
  color: #3a4145 !important;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

  input[type="submit"]:hover,
  input[type="reset"]:hover,
  input[type="button"]:hover,
  button:hover,
  .button:hover {
    border-color: #646464;
    color: #707070 !important;
  }


  input[type="submit"].fit,
  input[type="reset"].fit,
  input[type="button"].fit,
  button.fit,
  .button.fit {
    width: 100%;
  }



/* Footer */
#footer {
  width: 100%;
  padding: var(--space-6) 0;
  color: var(--color-neutral-100);
  text-align: center;
  margin-top: auto;
}

#footer .copyright {
  font-size: var(--font-size-sm);
  opacity: 0.8;
}
#footer .copyright li {
  display: inline-block;
  margin: 0 0 0 0.45em;
  padding: 0 0 0 0.85em;
  border-left: solid 1px rgba(255, 255, 255, 0.5);
  line-height: 1;
}
#footer .copyright li:first-child {
  border-left: 0;
}

/* Wrapper */
#wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: var(--space-4);
  perspective: 1000px;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  #main,
  #blogMain {
    width: 95%;
    padding: var(--space-6) var(--space-4);
  }

  .name-title {
    font-size: calc(var(--font-size-2xl) * 1.2);
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: var(--font-size-sm);
  }

  .avatar {
    width: 150px;
    height: 150px;
  }

  .content-section {
    padding: var(--space-4);
  }

  .name-title {
    font-size: var(--font-size-2xl);
  }

  .subtitle {
    font-size: var(--font-size-lg);
  }
}

@media screen and (max-width: 480px) {
  #wrapper {
    padding: var(--space-2);
  }

  #main,
  #blogMain {
    padding: var(--space-4) var(--space-3);
  }

  .avatar {
    width: 120px;
    height: 120px;
  }

  .avatar::before {
    left: -50%;
    width: 200%;
  }

  .content-section {
    padding: var(--space-3);
  }

  .name-title {
    font-size: var(--font-size-xl);
    margin: var(--space-4) 0 var(--space-2);
  }

  .subtitle {
    font-size: var(--font-size-base);
    margin-bottom: var(--space-6);
  }
}


/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

/* Loading States */
.loading {
  position: relative;
  pointer-events: none;
  opacity: 0.6;
}

/* Icons */

ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
  margin-top: -0.675em;
}

ul.icons li {
  display: inline-block;
  padding: 0.675em 0.5em;
}

ul.icons li a {
  text-decoration: none;
  position: relative;
  width: 3.75em;
  height: 3.75em;
  line-height: 3.75em;
  overflow: hidden;
  text-align: center;
  text-indent: 3.75em;
  white-space: nowrap;
}

ul.icons li a:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

ul.icons li a:before {
  color: #ffffff;
  text-shadow: 1.25px 0px 0px #c8cccf, -1.25px 0px 0px #c8cccf,
    0px 1.25px 0px #c8cccf, 0px -1.25px 0px #c8cccf;
}

ul.icons li a:hover:before {
  text-shadow: 1.25px 0px 0px #ff7496, -1.25px 0px 0px #ff7496,
    0px 1.25px 0px #ff7496, 0px -1.25px 0px #ff7496;
}

ul.icons li a:before {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  font-size: 1.85rem;
  line-height: inherit;
  text-align: center;
  text-indent: 0;
}

ul.icons li a:hover {
  border-color: #ff7496;
}

@media screen and (max-width: 480px) {
  ul.icons li a:before {
    font-size: 1.5rem;
  }
}
